body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .scroller{
    max-width: 1024px;
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    padding-right:50px;
    padding-left: 50px;
    overflow: hidden;
    margin: auto;
  }
.scroller__inner{
    column-gap:14px;
    display:flex;
    padding-block:2px;
    width:max-content;
    flex-wrap:nowrap;
    list-style: none;
    animation: scroll infinite 10s linear backwards;
    padding-inline-start: 0px;
  }
  @keyframes scroll {
    to {
      transform: translateX(calc(-100% - 0.5rem));
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
