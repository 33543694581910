div[id^="react-select"][id$="listbox"] {
    background-color: #212121;
    color: #fff;
    position: absolute!important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

div[id^="react-select"][id$="live-region"] {
    background-color: #212121;
}
@keyframes scroll {
    to {
      transform: translateX(calc(-50% - 0.5rem));
    }
  }